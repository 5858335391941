import React from 'react';
import { Container } from 'next/app';
import Head from 'next/head';
import * as Sentry from '@sentry/node';
import "../styles.css";


if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN
  });
}


export default function App({Component, pageProps, err}) {
    return (
      <Component {...pageProps} err={err}>
      </Component>
    );
}
